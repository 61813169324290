import React from 'react'

import { CostingHistory } from '../../components/views/history/costingHistory/costingHistory'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function () {
   return (
      <LayoutLoggedIn>
         <CostingHistory />
      </LayoutLoggedIn>
   )
}
